import React from 'react'
import './Hero.css'
import { Fade } from 'react-awesome-reveal';

const Hero = () => {
  return (
    <div className="hero_wrapper">
      <div className="hero_header">
        <div className="hero_header-text">
          <Fade>
            <h3>ThatGuyDencker</h3>
          </Fade>
          <p>The cool guy uknow?</p>
        </div>
      </div>
    </div>
  );
}

export default Hero