import React from 'react'
import { Fade, Zoom } from 'react-awesome-reveal'
import './Streams.css'

import Hero from './Hero/Hero'

const Streams = () => {
  return (
    <Fade>
      <div className="Dencker__streams-wrapper">
        <div className="Dencker__streams-hero">
          <Hero />
        </div>
      </div>
    </Fade>
  );
}

export default Streams