import React, { useState } from "react";
import { Rotate as Hamburger } from "hamburger-react";
import logo from "../../assets/img/logo_white_transparent.png";
import "./Navbar.css";
import StarfieldAnimation from "react-starfield-animation";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

const Navbar = (props) => {
  
  const [toggleMenu, setToggleMenu] = useState(false);

  const Menu = () => (
    <>
      <div className="Dencker__navbar-links_container">
        <p className="nav_menu hover-effect">
          <Fade>
            <Link to="/servers">
              Servers
            </Link>
          </Fade>
        </p>
        <p className="nav_menu hover-effect">
          <Link to="/systems">
            Systems
          </Link>
        </p>
        <p className="nav_menu hover-effect">
          <Link to="/websites">
            Websites
          </Link>
        </p>
        <p className="nav_menu hover-effect">
          <Link to="/streams">
            Streams
          </Link>
        </p>
      </div>
    </>
  );

  return (
    <Fade triggerOnce style={{width: "100%"}}>
      <div className="navbar-wrapper">
        <div className="Dencker__navbar">
          <StarfieldAnimation
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
            numParticles={600}
            particleSpeed={1}
            dx={0.025} // x speed of stars in px/frame, default 0.05
            dy={0.025}
          />
          <div className="Dencker__navbar-links">
            <div className="Dencker__navbar-links_logo">
              <img src={logo} alt="logo" />
            </div>

              <Menu />
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default Navbar;
