import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

// Import containers
import Navbar from "./container/navbar/Navbar.jsx";
import Servers from "./container/servers/Servers.jsx";
import Systems from "./container/systems/Systems.jsx";
import Websites from "./container/websites/Websites.jsx";
import Streams from "./container/streams/Streams";

const Home = () => {
  return (
      <div className="Dencker__navbar-links_container">
        <Navbar />
      </div>
  );
};

function App() {
  return (
    <div className="App fixed" id="home">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/servers" element={<Servers />} />
          <Route path="/systems" element={<Systems />} />
          <Route path="/websites" element={<Websites />} />
          <Route path="/streams" element={<Streams />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
